<template>
    <div class="view campaign">
        <section class="hero is-dark is-bold">
            <div class="bg-filter"></div>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        Campaigns
                    </h1>
                    <!-- <h2 class="subtitle">
                        subtitle
                    </h2> -->
                </div>
            </div>
            <div class="hero-foot">
                <nav class="tabs is-boxed is-fullwidth">
                    <div class="container">
                        <ul>
                            <li v-bind:class="{ 'is-active': $route.name === 'Campaign-settings' }">
                            <router-link to="/campaign">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="cog"></font-awesome-icon>
                                    </span>
                                <span>
                                        Settings
                                    </span>
                            </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['Campaign-enquiries'].includes($route.name) }">
                            <router-link to="/campaign/enquiries">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="question"></font-awesome-icon>
                                </span>
                                <span>
                                    Enquiries
                                </span>
                            </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "Campaign"
    }
</script>

<style lang="scss" scoped>

</style>
